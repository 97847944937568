<template>
  <b-tabs>
    <b-tab
      title="Müşteri siparişleri"
      active
    >
      <InvoiceList />
    </b-tab>
    <b-tab title="Sipariş talepleri">
      <OrderList />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import InvoiceList from '../invoice/index.vue'
import OrderList from './index.vue'

export default {
  components: {
    BTabs,
    BTab,
    InvoiceList,
    OrderList,
  },
}
</script>
